<template>
    <el-row :gutter="10" class="w-full"
        v-if="isShow"
    >
        <el-col
            v-if="isShowTitle"
            :span="24"
            class="special-title"
            :class="{required: isRequired}"
        >
            <label>
                {{ label }}
            </label>
        </el-col>
        <el-col
            v-if="headerText"
        >
            {{ headerText }}
        </el-col>
        <el-col
            v-else-if="isNeedHeader"
        >
            <el-checkbox
                v-model="isApplySame"
                :disabled="readOnly"
                label="Співпадає з адресою реєстрації"
            />
        </el-col>
        <el-col
            v-for="(q, key) in questions"
            :key="`q-${key}${num}`"
            :span="getWeight(q)"
        >
            <QuestionField
                :question="q"
                :doc="doc"
                :is-special="isSame"
                :num="getQuestionNum()"
                :readOnly="readOnly"
            />
        </el-col>
    </el-row>
</template>

<script>
import {count, forEach} from "@candybox/helpers";
import {get} from 'lodash';
import QuestionField from "@app/questionnaire/components/question/QuestionField";
import getFieldLabel from "@app/questionnaire/components/mixins/getFieldLabel";
import viewMode from "@app/questionnaire/components/mixins/viewMode";
import constants from "@app/core/constants.js";
import showHidedFieldMixin from "@app/questionnaire/components/mixins/showHidedFieldMixin.js";

export default {
    name: "SubBlockField",
    props: {
        subblock: {
            type: Object,
            required: true,
        },
        doc: {
            type: Object,
            required: true,
        },
        parentAttrs: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        QuestionField,
    },
    mixins: [
        getFieldLabel,
        viewMode,
        showHidedFieldMixin,
    ],
    data() {
        return {
            isApplySame: false,
        };
    },
    computed: {
        questions() {
            return this.filterQuestions(get(this.subblock, 'questions', []));
        },
        isSingle() {
            return count(this.questions) === 1;
        },
        isShow() {
            if(this.isSingle) {
                const question = this.questions[0];
                const conditionField = get(question, 'condition_field');
                if(conditionField) {
                    return !!get(this.parentAttrs, conditionField, false) || !!get(this.doc, conditionField);
                }
            }
            return true;
        },
        title() {
            return get(this.subblock, 'title');
        },
        isSame() {
            return !this.title && this.isSingle;
        },
        label() {
            return this.getLabel(this.subblock.title);
        },
        isShowTitle() {
            return this.title || !this.isSame;
        },
        headerText() {
            return get(this.subblock, 'header_text');
        },
        isNeedHeader() {
            if(this.title) {
                return this.title.startsWith('Фактична адреса проживання');
            }
            return false;
        },
        isRequired() {
            return get(this.subblock, 'is_required') === 1;
        }
    },
    watch: {
        isApplySame(val) {
            this.applySame(val);
        }
    },
    methods: {
        getWeight(question) {
            const type = get(question, 'view_type');
            if(type !== constants.QUESTION_VIEW_TYPE.CHECKBOX_SET && !this.isSingle) {
                return 12;
            }
            return 24;
        },
        getQuestionNum() {
            return this.isSingle ? this.num : null;
        },
        applySame(apply) {
            const equalLinks = {
                residential_state: 'registration_state',
                residential_region: 'registration_region',
                residential_district: 'registration_district',
                residential_locality: 'registration_locality',
                residential_street: 'registration_street',
                residential_building: 'registration_building',
                residential_house: 'registration_house',
                residential_apartment: 'registration_apartment',
                residential_room: 'registration_room',
                residential_index: 'registration_index',
            }
            forEach(equalLinks, (item, key) => {
                this.doc[key] = apply ? this.doc[item] : null;
            })
        },
    }
}
</script>
