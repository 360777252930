export default {
    MONTH_OF_CLASS_NUMBER_CHANGE: 6,
    QUESTIONNAIRE_CONSTRUCTOR: {
        TYPE: 'type',
        BLOCK: 'block',
        SUBBLOCK: 'subblock',
        QUESTION: 'question',
        ANSWER: 'answer',
    },
    QUESTION_VALUE_TYPE: {
        VALUE_TYPE_STRING: 'string',
        VALUE_TYPE_TEXT: 'text',
        VALUE_TYPE_INTEGER: 'integer',
        VALUE_TYPE_BOOLEAN: 'boolean',
        VALUE_TYPE_JSON: 'json',
    },
    QUESTION_VIEW_TYPE: {
        CHECKBOX_SET: 'checkboxset',
        DROPDOWN: 'dropdown',
        SIMPLE: 'simple',
    },
    ANSWER_VIEW_TYPE: {
        CHECKBOX_SET: 'checkboxset',
        DROPDOWN: 'dropdown',
        SIMPLE: 'simple',
    },
    QUESTIONNAIRE_TYPE: {
        SOCIAL: 1,
        STANDARD: 2,
        PUPIL: 3,
        COMMUNAL: 4,
        STUDENT: 5,
        PRIVATE: 6,
        VOLUNTEER: 7,
        DSNS: 8,
        VPO: 9,
    },
    QUESTIONNAIRE_STATUS: {
        NEW: 'new',
        REJECT: 'reject',
    },
    QUESTIONNAIRE: {
        SOURCE: {
            ADMIN: 'Оцифровка',
        },
    },
    CALL: {
        COMPLAINT: 'complaint',
        REQUEST_TYPES: {
            REPEATED: 0,
            FIRST: 1,
        },
    },
    CHART_TYPE: {
        BAR: 'BarChart',
        COLUMN: 'ColumnChart',
        PIE: 'PieChart',
        LINE: 'LineChart',
    },

    CLIENT: {
        STATUS: {
            CLIENT: 'client',
            GUEST: 'guest',
        },
        REGISTRATION: {
            STATUS: {
                NEW: 'new',
                REGISTERED: 'registered',
                CONFIRMED: 'confirmed',
            },
        },
    },
    USER: {
        TYPE: {
            EMPLOYEE: 'employees',
            CLIENT: 'questionnaire',
            OPERATOR_TASK: 'operatorTask',
        },
    },
    PROCESS_LOG: {
        STATUS: {
            PROCESSED: 'processed',
            NONPROCESSED: 'nonprocessed',
            IMPROCESSIBLE: 'improcessible',
            RECALL: 'recall'
        },
        PATIENT_TYPE: {
            PATIENT: '1',
            NOT_PATIENT: '0',
        },
        VISIT_TYPE: {
            FIRST: '1',
            RETURN: '0',
        },
        IMPROCESSIBLE_REASON_OTHER: '0',
    },
    CALL_ACTION: {
        TYPE: {
            CREATE: 'create',
            UPDATE: 'update',
        },
        SUBJECT: {
            PATIENT: 'patient',
            CALL: 'call',
            APPOINTMENT: 'appointment',
            CALL_LOG: 'call_log',
        },
    },
    SAMPLE: {
        STATUS: {
            ARCHIVED: 'archived',
            PAUSED: 'paused',
        }
    },
    OPERATOR_TASK: {
        STATUS: {
            WAITING: 'waiting',
            WORK: 'work',
            ENDED: 'ended',
        }
    },
    ROLES: {
        ADMIN_HP: 'Адміністратор КХ',
        ADMIN_JUNIOR: 'Молодший адмінстратор КХ',
    },
    INSTITUTION: {
        TYPE: {
            SCHOOL: 'school',
            UNIVERSITY: 'university',
        }
    },
    PRODUCTS: {
        IMPORT_ENTITY: {
            CHECK: 'checks',
            PRODUCT: 'products',
        },
        IMPORT_ENTITY_STATUS: {
            SUCCESS_IMPORT: 'success_import',
        }
    }
}
