<template>
    <form-row
        :label="label"
        :errors="$errors[answer.field_name]"
        :required="isRequired"
        :class="{ 'special-title': isSpecial }"
    >
        <el-date-picker
            v-if="isDate || isDateEnd"
            v-model="docField"
            :type="type"
            format="DD.MM.YYYY"
            :disabled="readOnly"
            :disabled-date="isDate ? disabledDate : null"
        />
        <options-provider
            v-else-if="isBoolean"
            source="yes_no">
            <template v-slot:default="data">
                <el-radio-group
                    v-model="docField"
                    :disabled="readOnly"
                >
                    <el-radio-button
                        v-for="option in data.items"
                        :key="option.id"
                        :label="option.id">
                        {{ option.value }}
                    </el-radio-button>
                </el-radio-group>
            </template>
        </options-provider>
        <el-input
            v-else-if="isPhone"
            v-model="docField"
            :disabled="readOnly"
        >
            <template v-slot:prepend>
                <span class="centered">+38</span>
            </template>
        </el-input>
        <el-input
            v-else-if="isINN"
            v-model="docField"
            :disabled="readOnly"
        >
            <template v-slot:append>
                <div
                    class="pl-5"
                >
                    <el-button
                        @click="redirectToQuestionnaires"
                        :disabled="!isAllowRedirect"
                    >
                        {{ __('Дублікати') }}
                    </el-button>
                </div>
            </template>
        </el-input>
        <el-input
            v-else
            v-model="docField"
            :disabled="readOnly"
        />
    </form-row>
</template>

<script>
import questionFieldMixin from "@app/questionnaire/components/question/mixins/questionFieldMixin";
import {toArray, count, set, filter} from "@candybox/helpers";
import { format, parse } from 'date-fns'
import { get, includes, size } from 'lodash';
import viewMode from "@app/questionnaire/components/mixins/viewMode";
import docMixin from "@app/questionnaire/components/questionnaires/mixins/docMixin.js";

export default {
    name: "Simple",
    mixins: [
        questionFieldMixin,
        viewMode,
        docMixin,
    ],
    data() {
        return {
            dateFormat: 'DD.MM.YYYY',
        };
    },
    computed: {
        docField: {
            get() {
                return get(this.doc, this.answer.field_name, '');
            },
            set(value) {
                set(this.doc, this.answer.field_name, value);
            }
        },
        answer() {
            return count(this.questionAnswers) > 0 ? this.questionAnswers[0] : null;
        },
        type() {
            return get(this.answer, 'input.type.name');
        },
        isDate() {
            return this.type === 'date';
        },
        isDateEnd() {
            return this.type === 'date_end';
        },
        isBoolean() {
            return this.type === 'boolean';
        },
        isPhone() {
            return includes(['contact_number', 'additional_number'], this.answer.field_name)
        },
        isINN() {
            return get(this.answer, 'field_name') === 'rnokpp';
        },
        isAllowRedirect() {
            if(this.isINN) {
                const inn = this.getInn;
                const innErrors = get(this.$errors, 'rnokpp');
                return (!!inn && size(inn) === 10) && includes(innErrors, "Це поле має бути унікальним");
            }
            return false;
        }
    },
    methods: {
        async init() {
            const questionnaireAnswers = !this.doc.id ? [] : this.doc.answers;
            const answerQuestionnaireList = toArray(filter(questionnaireAnswers, (item) => {
                return item.answer_id === this.answer.id;
            }));
            const answerQuestionnaire = count(answerQuestionnaireList) ? answerQuestionnaireList[0] : null;
            const val = get(answerQuestionnaire, 'value.input_value', null);
            this.docField = this.getValueByType(val, this.type);
        },
        getValueByType(sStr, type) {
            if(!this.doc.id) {
                return this.getDefaults(this.answer.field_name);
            }

            if(!sStr) {
                return null;
            }
            if(type === 'date' || type === 'date_end') {
                return parse(sStr, 'dd.MM.yyyy', new Date());
            }

            return sStr;
        },
        setValueByType(value, type) {
            if(type === 'date' || type === 'date_end') {
                const _date = new Date(value);
                return format(_date, 'dd.MM.yyyy');
            }

            return value
        },
        redirectToQuestionnaires() {
            const route = this.$router.resolve({
                name: 'questionnaires',
                query: {
                    rnokpp_attr: this.getInn,
                }
            });
            window.open(route.href, '_blank');
        },
        disabledDate(time) {
            let date = new Date();
            return (time > date);
        }
    },
    watch: {
        docField(val) {
            this.doc.answers_to_save[this.answer.id] = {
                input_value: this.answer.hasHandbook ? null : this.setValueByType(val, this.type),
                answer_input_value_id: this.answer.hasHandbook ? val : null,
                handbook_name: this.answer.handbook_name,
                answer_id: this.answer.id,
                input_type_id: get(this.answer, 'input.type.id'),
            };
        }
    }
}
</script>
