<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="12">
                    <form-upload
                        property="pes_files_ids"
                        :readOnly="readOnly"
                        :entity="doc"
                        multiple
                        showFileList
                        :showButton="false"
                        :pesTip="true"
                    />
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col>
                <el-button
                    v-if="$can('questionnaire.download') && isAllowDownload"
                    type="primary"
                    @click="download"
                >
                    {{ __('Завантажити') }}
                </el-button>
            </el-col>
        </el-row>
    </section>
</template>

<script>

import {get} from 'lodash';
import questionnaires from "@app/questionnaire/repositories/questionnaires.js";
import {count} from "@candybox/helpers.js";

export default {
    name: "pesUploadForm",
    props: {
        doc: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        pesFiles() {
            return get(this.doc, 'pes_files_ids', []);
        },
        isAllowDownload() {
            return count(this.pesFiles) > 0;
        },
    },
    methods: {
        download() {
            questionnaires()
                .downloadFiles(this.pesFiles)
                .then((blobUrl) => {
                    let link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', 'export.zip')
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
        }
    },
}
</script>
