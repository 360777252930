import { isFilled } from '@app/core/helpers.js';
import { get } from '@candybox/helpers.js';
import { translator } from '@app/core/services/translation.js';
import validate from 'validate.js';

const phoneFormats = {
    local: new RegExp('^[0-9]{10}$'),
    intl: new RegExp('^\\+[0-9]{10,15}$'),
    intlNoPlus: new RegExp('^[0-9]{10,15}$'),
    ukIntl: new RegExp('^\\+380[0-9]{9}$'),
    ukIntlNoPlus: new RegExp('^380[0-9]{9}$'),
}

const sipFormat = new RegExp('^[0-9]{3,4}$');

const pensionFormat = new RegExp('^[А-Я]{2}[0-9]{6}$');
const passportSeriesFormat = new RegExp('^[А-ЯЄІЇҐЮ]{2}$');

const ukrainianLanguageFormat = new RegExp('^[А-ЩЬЄІЇҐЮ-щьюяєіїґ\'`ʼ’-]+$');
const upperCaseFormat = new RegExp('^[А-ЯІЇЄҐ][а-яіїєґ\'`ʼ’-]+$');
const properCaseFormat = new RegExp('^[А-ЯІЇЄҐ][а-яіїєґ\'`ʼ’]*(-[А-ЯІЇЄҐ][а-яіїєґ\'`ʼ’]*)?$');

const studentCardNumberFormat = new RegExp('^[A-ZА-ЯЄЇІҐ]{2}[0-9]{8}$');
const referenceNumberFormat = new RegExp('^[0-9]{4}-[0-9]{10}$');

const setupValidator = () => {
    let t = translator();
    validate.options = {fullMessages: false};
    validate.validators.presence.options = {message: t.translate('Це поле є обов’язковим')};
    validate.validators.email.options = {message: t.translate('Цей email не є коректним')};
    validate.validators.length.options = {
        wrongLength: t.translate('Це поле має бути {count} символів', {count: '%{count}'}),
        tooLong: t.translate('Це поле не може бути довшим за {count} символів', {count: '%{count}'})
    };
    validate.validators.numericality.options = {message: t.translate('Це поле має бути числом')};
}

const phoneNumber = (value, options) => {
    if (isFilled(value)) {
        let format = get(options, 'format', 'local');

        if (phoneFormats[format] === undefined) {
            throw Error('Invalid phone number format');
        }

        if (!phoneFormats[format].test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Невірний формат номеру');
            });
        }
    }
}

const sipNumber = (value, options) => {
    if (isFilled(value)) {
        if (!sipFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Невірний формат SIP');
            });
        }
    }
}

const pensionNumber = (value, options) => {
    if (isFilled(value)) {
        if (!pensionFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Невірний формат пенсійного. Потрібно 2 заголовних букви(кирилиця) + 6 цифр');
            });
        }
    }
}

const passportSeries = (value, options) => {
    if (isFilled(value)) {
        if (!passportSeriesFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Невірний формат номеру паспорта. Потрібно 2 заголовних букви(кирилиця)');
            });
        }
    }
}

const isEqual = (value, options) => {
    if (isFilled(value)) {
        const equalValue = get(options, 'equalValue');
        if(value === equalValue) {
            return get(options, 'message', () => {
                return translator().translate('Невірне значення');
            });
        }
    }
}

const ukrainianLanguageValidator = (value, options) => {
    if (isFilled(value)) {
        if (!ukrainianLanguageFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Поле повинно містити тільки українські символи кирилиці.');
            });
        }
    }
}

const upperCaseValidator = (value, options) => {
    if (isFilled(value)) {
        if (!upperCaseFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Поле повинно починатися з великої літери, всі інші мають бути малими.');
            });
        }
    }
}

const properCaseValidator = (value, options) => {
    if (isFilled(value)) {
        if (!properCaseFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Поле повинно починатися з великої літери, також після дефісу з великої літери.');
            });
        }
    }
}

const studentCardNumberValidator = (value, options) => {
    if (isFilled(value)) {
        if (!studentCardNumberFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Поле повинно бути наступного формату: ААXXXXXXXX, де АА-букви, Х-цифри');
            });
        }
    }
}

const referenceNumberValidator = (value, options) => {
    if (isFilled(value)) {
        if (!referenceNumberFormat.test(value)) {
            return get(options, 'message', () => {
                return translator().translate('Поле повинно бути наступного формату: ХХХХ-ХХХХХХХХХХ, де Х - це цифра');
            });
        }
    }
}

export {
    phoneNumber,
    sipNumber,
    pensionNumber,
    passportSeries,
    setupValidator,
    isEqual,
    ukrainianLanguageValidator,
    upperCaseValidator,
    properCaseValidator,
    studentCardNumberValidator,
    referenceNumberValidator
}
