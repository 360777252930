<template>
    <el-tabs
        v-model="activeTab"
        v-loading="loading"
    >
        <el-tab-pane
            :label="__('Загальні ознаки')"
            name="general">
            <section class="dialog-body">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <form-row
                            :label="__('Тип анкети')"
                            :errors="$errors['questionnaire_type_id']"
                            required
                            :itemBuilder="typeValueBuilder()"
                        >
                            <options-provider
                                :source="getTypes()"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        :loading="data.loading"
                                        :disabled="isReadOnly"
                                        v-model="doc.questionnaire_type_id"
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :disabled="item.disabled"
                                            :label="item.value"
                                            :value="item.id" />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Статус')"
                            :errors="$errors['questionnaire_status_id']"
                            required
                        >
                            <options-provider
                                :source="getQuestionnaireStatuses()"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        :loading="data.loading"
                                        :disabled="isReadOnly"
                                        v-model="doc.questionnaire_status_id">
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :disabled="item.disabled"
                                            :label="item.value"
                                            :value="item.id" />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>

                    <el-col :span="12">
                        <form-row
                            :label="__('Цифрова картка')"
                            :errors="$errors['has_digital']"
                        >
                            <options-provider
                                source="yes_no"
                            >
                                <template v-slot:default="data">
                                    <el-radio-group
                                        v-model="doc.has_digital"
                                        :disabled="isReadOnly"
                                    >
                                        <el-radio-button
                                            v-for="option in data.items"
                                            :key="option.id"
                                            :label="option.id">
                                            {{ option.value }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>

                    <el-col :span="12">
                        <form-row
                            :label="__('Пластикова картка')"
                            :errors="$errors['has_plastic']"
                        >
                            <options-provider
                                source="yes_no"
                            >
                                <template v-slot:default="data">
                                    <el-radio-group
                                        v-model="doc.has_plastic"
                                        :disabled="isReadOnly"
                                    >
                                        <el-radio-button
                                            v-for="option in data.items"
                                            :key="option.id"
                                            :label="option.id">
                                            {{ option.value }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>

                    <el-col :span="24">
                        <form-row
                            :label="__('Згода на обробку персональних даних')"
                            :errors="$errors['is_agree']"
                        >
                            <options-provider
                                source="yes_no"
                            >
                                <template v-slot:default="data">
                                    <el-radio-group
                                        v-model="doc.is_agree"
                                        :disabled="isReadOnly"
                                    >
                                        <el-radio-button
                                            v-for="option in data.items"
                                            :key="option.id"
                                            :label="option.id">
                                            {{ option.value }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>

                    <el-col :span="hasChip ? 24 : 18">
                        <form-row
                            :label="__('З чіпом')"
                            :errors="$errors['has_chip']"
                        >
                            <options-provider
                                source="yes_no"
                            >
                                <template v-slot:default="data">
                                    <el-radio-group
                                        v-model="doc.has_chip"
                                        :disabled="isReadOnly"
                                    >
                                        <el-radio-button
                                            v-for="option in data.items"
                                            :key="option.id"
                                            :label="option.id">
                                            {{ option.value }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="6"
                        v-if="!hasChip"
                    >
                        <form-row
                            :label="__('Умова видачі')"
                            :errors="$errors['issue_condition_id']"
                            required
                        >
                            <options-provider
                                :source="issueConditions"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        v-model="doc.issue_condition_id"
                                        :disabled="isReadOnly"
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>

                    <el-col :span="24">
                        <form-row
                            :label="__('Коментар оператора')"
                            :errors="$errors['operator_note']"
                        >
                            <el-input
                                v-model="doc.operator_note"
                                type="textarea"
                                maxlength="512"
                                show-word-limit
                                :rows="4"
                            />
                        </form-row>
                    </el-col>

                  <el-col :span="12">
                    <form-row
                      :label="__('Місце отримання анкети')"
                      :errors="$errors['place_of_receiving_id']"
                    >
                      <options-provider
                        :source="cnaps"
                      >
                        <template v-slot:default="data">
                          <el-select
                            v-model="doc.place_of_receiving_id"
                            :loading="data.loading"
                            :disabled="isReadOnly"
                            filterable
                          >
                            <el-option
                              v-for="item in data.items"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id" />
                          </el-select>
                        </template>
                      </options-provider>
                    </form-row>
                  </el-col>
                  <el-col :span="12">
                    <form-row
                      :label="__('Реквізити акта приймання передачі')"
                      :errors="$errors['act_of_acceptance']"
                    >
                      <el-input
                        v-model="doc.act_of_acceptance"
                      />
                    </form-row>
                  </el-col>

                    <el-col :span="24">
                        <form-row
                            :label="__('Коментар')"
                            :errors="$errors['note']"
                        >
                            <el-input
                                v-model="doc.note"
                                disabled
                                type="textarea"
                                :rows="4"
                                resize="none"
                            />
                        </form-row>
                    </el-col>

                    <el-col :span="12">
                        <form-row
                            :label="__('Архів')"
                            :errors="$errors['is_archive']"
                        >
                            <options-provider
                                source="yes_no"
                            >
                                <template v-slot:default="data">
                                    <el-radio-group
                                        v-model="doc.is_archive"
                                        :disabled="isReadOnly"
                                    >
                                        <el-radio-button
                                            v-for="option in data.items"
                                            :key="option.id"
                                            :label="option.id">
                                            {{ option.value }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Теги')"
                            :errors="$errors['changed_tags']"
                        >
                            <options-provider
                                :source="tags"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        v-model="doc.changed_tags"
                                        :loading="data.loading"
                                        :disabled="isReadOnly"
                                        multiple
                                        clearable
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :disabled="item.disabled"
                                            :label="item.value"
                                            :value="item.id" />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>

                    <el-col
                        v-if="isShowPhoto"
                        :span="12"
                    >
                        <form-row
                            :label="__('Фотокартка')"
                            :errors="$errors['photo_id']"
                        >
                            <cropper-upload
                                ref="photo"
                                property="photo_id"
                                :readOnly="isReadOnly"
                                :entity="doc"
                                :limit="1"
                                :onPreview="true"
                                :showFileList="false"
                                :multiple="false"
                            />
                            <img
                                v-if="doc.photo && doc.photo.url"
                                :src="doc.photo.url"
                                alt="photo"
                                class="img-preview"
                            >
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>

        <el-tab-pane
            :label="__('Скан-копії')"
            name="skan-copy"
        >
            <section class="dialog-body">
                <el-row :gutter="20" class="mb-15">
                    <el-col :span="24">
                        <el-descriptions :column="1" border>
                            <el-descriptions-item
                                label="Фото:"
                                label-align="center">
                                <el-row :gutter="20">
                                    <el-col :span="8" v-for="(img, index) in doc.images">
                                        <el-image
                                            :src="img.url"
                                            :key = index
                                            :zoom-rate="1.2"
                                            :initial-index="index"
                                            :preview-src-list="imgUrlList"
                                            fit="cover"/>
                                    </el-col>
                                </el-row>
                            </el-descriptions-item>
                            <el-descriptions-item
                                label="Файли:"
                                label-align="center">
                                <el-row :gutter="20">
                                    <pesUploadForm
                                        :doc="doc"
                                        :readOnly="true"
                                    />
                                </el-row>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>

        <el-tab-pane
            v-if="isChild"
            :label="__('Анкета дорослого')"
            name="parents"
        >
            <parentForm
                :doc="doc"
                :readOnly="isReadOnly"
                @createQuestionnaire="createQuestionnaire"
            />
        </el-tab-pane>
        <el-tab-pane
            v-if="isAllowOrders"
            :label="__('Накази')"
            name="orders"
        >
            <uploadForm
                :doc="doc"
                :readOnly="isReadOnly"
            />
        </el-tab-pane>
        <el-tab-pane
            v-for="(block, key) in blocks"
            :key="`b-${key}-${block.id}-${currentType.name}`"
            :label="truncateString(block.label)"
            :name="block.name"
        >
            <section class="dialog-body">
                <el-row :gutter="20"
                    class="question"
                >
                    <el-col
                        v-for="(subblock, key__) in block.subblocks"
                        :key="`sb-${key__}-${subblock.id}-${currentType.name}`"
                        class="subblock"
                    >
                        <SubBlockField
                            :subblock="subblock"
                            :doc="doc"
                            :num="fullSubblocks[subblock.id]"
                            :readOnly="isReadOnly"
                            :parentAttrs="attrs"
                        />
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import Query from '@candybox/query/query.js';
import types from "@app/questionnaire/repositories/types";
import {get, toArray, filter, count} from "@candybox/helpers";
import SubBlockField from "@app/questionnaire/components/subblock/SubBlockField";
import {truncateString} from "@app/core/helpers";
import constants from "@app/core/constants";
import statuses from "@app/questionnaire/repositories/statuses.js";
import {find, first, set, includes, map} from "lodash";
import parentForm from "@app/questionnaire/components/questionnaires/pupil/parentForm.vue";
import uploadForm from "@app/questionnaire/components/questionnaires/pupil/uploadForm.vue";
import pesUploadForm from "@app/questionnaire/components/questionnaires/pes/pesUploadForm.vue";
import blocks from "@app/questionnaire/repositories/blocks.js";
import questionnaires from "@app/questionnaire/repositories/questionnaires";
import showHidedFieldMixin from "@app/questionnaire/components/mixins/showHidedFieldMixin.js";
import tags from "@app/questionnaire/repositories/tags.js";
import glossaries from "@app/handbook/repositories/glossary.js";
import getGlossariesMixin from "@app/employee/components/employees/mixins/getGlossariesMixin.js";

export default {
    components: {
        SubBlockField,
        parentForm,
        uploadForm,
        pesUploadForm,
    },
    props: {
        doc: {
            type: Object,
            default: null,
        },
        attrs: {
            type: Object,
            default: () => ({}),
        }
    },
    mixins: [
        showHidedFieldMixin,
        getGlossariesMixin,
    ],
    data() {
        return {
            activeTab: 'general',
            types: null,
            cnaps: [],
            blocks: [],
            questionsCount: 0,
            tags: [],
            fullSubblocks: null,
            image: null,
            loading: false,
            statuses: [],
            issueConditions: [],
        }
    },
    computed: {
        isShowPhoto() {
            return includes([
                constants.QUESTIONNAIRE_TYPE.PUPIL,
                constants.QUESTIONNAIRE_TYPE.VOLUNTEER,
            ], this.doc.questionnaire_type_id);
        },
        imgUrlList() {
            let list_ = [];
            this.doc.images.forEach((img) => {
                list_.push(img.url);
            })
            return list_;
        },
        isReadOnly() {
            return get(this.attrs, 'readOnly', false);
        },
        currentType: {
            get() {
                return this.doc.$type.$;
            },
            set(val) {
                return this.doc.$type = val;
            },
        },
        isChild() {
            return get(this.currentType, 'id') === constants.QUESTIONNAIRE_TYPE.PUPIL;
        },
        hasChip() {
            return get(this.doc, 'has_chip') === '1';
        },
        isAllowOrders()
        {
            const orderFiles = get(this.doc, 'order_files_ids', []);

            return count(orderFiles) > 0 || this.isChild;
        },
    },
    created() {
        if(!this.doc.id) {
            this.initDefaults();
        }
        const childAttr = get(this.attrs, 'child_id', null);
        if(childAttr) {
            this.doc.child_id = childAttr;
        }
        this.getPlacesOfReceiving();
        this.updateQuestionnaireAnswers();
        this.getTags();
        this.getIssueConditions();
    },
    methods: {
        async initDefaults() {
            const glossaries = await this.getGlossaries('questionnaire_source');
            const source = find(glossaries, (item) => item.value === constants.QUESTIONNAIRE.SOURCE.ADMIN)
            this.doc.source_id = get(source, 'id');
            this.doc.responsible_name = get(this.$user, 'employee.full_name');
        },
        getIssueConditions() {
            glossaries()
                .search((new Query).where('type', '=', 'chip'))
                    .then((items) => {
                        this.issueConditions = items.map((glossaryItem) => ({
                          id: glossaryItem.id,
                          value: glossaryItem.name
                        }));
                  })
        },
        updateQuestionnaireAnswers() {
            if(this.doc.id) {
                this.getQuestionnaireAnswers()
                    .then((results) => {
                        this.doc.answers = results;
                    });
            }
        },
        getTypes() {
            return types()
                .search((new Query).limitTo(1000))
                .then((items) => {
                    this.types = items.map((type) => ({
                        id: type.id,
                        value: type.name,
                        disabled: this.checkDisabledType(type),
                        blocks: type.$blocks,
                    }));
                    this.updateCurrentType(this.doc.questionnaire_type_id);
                    return this.types;
                });
        },
        getPlacesOfReceiving() {
          glossaries()
            .search((new Query).where('type', '=', 'cnap'))
            .then((items) => {
              this.cnaps = items.map((position) => ({
                id: position.id,
                value: position.name,
              }));
            });
        },
        checkDisabledType(type) {
            const disabledTypes = get(this.attrs, 'disabledTypes', []);
            return includes(disabledTypes, get(type, 'id'));
        },
        updateCurrentType(id) {
            this.currentType = this.types.find((type) => {
                return type.id === id;
            });
        },
        updateBlocks() {
            if(this.currentType) {
                this.loading = true;
                    blocks()
                        .search(
                            (new Query)
                                .where('questionnaire_type_id', '=', this.currentType.id)
                        )
                        .then((items) => {
                            const items_ = this.filterBlocks(items);
                            this.blocks = items_.map((item) => ({
                                id: item.id,
                                label: item.title,
                                name: `block ${item.id + 1}`,
                                subblocks: this.filterSubblocks(item.subblocks),
                            }))
                            this.calculateQuestionCount(this.blocks);
                            this.loading = false;
                            return this.blocks;
                        })
            }
        },
        calculateQuestionCount(blocks) {
            const _arr = {};
            let i = 1;
            for(let block of blocks) {
                const subblocks = this.filterSubblocks(get(block, 'subblocks', []));
                for(let subblock of subblocks) {
                    let needCount = true;
                    const questions = toArray(filter(get(subblock, 'questions'), (question) => {
                        return get(question, 'condition_field')
                    }));
                    if(questions) {
                        const question = questions[0];
                        const conditionField = get(question, 'condition_field');
                        if(conditionField) {
                            needCount = !!get(this.attrs, conditionField, false) || !!get(this.doc, conditionField);
                        }
                    }
                    if(needCount) {
                        _arr[subblock.id] = i;
                        i++;
                    }
                }
            }
            this.fullSubblocks = _arr;
        },
        getTags() {
            tags()
                .search(
                    (new Query).limitTo()
                )
                .then((items) => {
                    this.tags = map(items, (item) => ({
                        id: item.id,
                        value: item.glossary.name,
                        disabled: item.is_enabled === '0',
                    }))
                })
        },
        truncateString(str) {
            return truncateString(str);
        },
        typeValueBuilder() {
            return (item) => ({
                id: item.id,
                value: get(item, 'name'),
                disabled: get(item, 'disabled'),
            });
        },
        getQuestionnaireStatuses() {
            return statuses()
                .search((new Query).limitTo())
                .then((items) => {
                    this.statuses = items.map((status) => ({
                        id: status.id,
                        value: status.name,
                        code: status.code,
                        disabled: !status.is_show,
                    }));

                    return this.statuses;
                })
        },
        createQuestionnaire() {
            this.$emit('updating', {
                doc: this.doc,
            });
        },
        getQuestionnaireAnswers() {
            return questionnaires()
                .search(
                    (new Query)
                        .select('answers.*', 'orderFiles.*')
                        .where('id', '=', this.doc.id)
                )
                .then((res) => {
                    const item = first(res);
                    return get(item, 'answers');
                })
        },
    },
    watch: {
        ['doc.questionnaire_type_id'](id) {
            this.updateCurrentType(id);
            this.updateQuestionnaireAnswers();
        },
        ['doc.questionnaire_status_id'](id) {
            const status = find(this.statuses, (item) => get(item, 'id') === id);
            set(this.doc, 'status.code', get(status, 'code'));
        },
        ['currentType.value']() {
            this.updateBlocks();
        },
        hasChip(val) {
            if(val) {
                this.doc.issue_condition_id = null;
            }
        },
    }
}
</script>
