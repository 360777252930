import { createApp } from 'vue';
import store from "@app/store.js";
import { createRouter,
    createWebHashHistory } from 'vue-router';
import Validator, {
    ValidatorSymbol } from '@candybox/validation/validator.js';
import { TransportSymbol } from '@candybox/transport/base.js';
import HttpTransport from '@candybox/transport/http.js';
import TranslationServer, {
    TranslatorSymbol } from '@app/core/services/translation.js';
import App from '@candybox/app.js';
import ImageCropper from "@app/core/components/form/ImageCropper";
import Upload from "@app/core/components/form/Upload.vue";
import Cropper from "@app/core/components/form/Cropper.vue";
import root from '@app/core/components/root.vue';
import home from '@app/core/components/home.vue';
import page from '@app/core/components/page.vue';
import resources from '@app/core/components/page-groups/resources.vue';
import settings from '@app/core/components/page-groups/settings.vue';
import infrastructures from "@app/core/components/page-groups/infrastructures.vue";
import categories from "@app/core/components/page-groups/categories.vue";
import questionnaires from '@app/core/components/page-groups/questionnaires.vue';
import constructor from "@app/core/components/page-groups/constructor";
import card from '@app/core/components/page-groups/card.vue';
import callCenter from "@app/call-center/components/call-center/CallCenter.vue";
import distributor from '@app/core/components/page-groups/distributor.vue';
import script from "@app/core/components/page-groups/script";
import analytics from "@app/core/components/page-groups/analytics.vue";
import partner from "@app/core/components/page-groups/partner.vue";
import report from "@app/core/components/page-groups/report.vue";
import svgIcon from '@app/core/components/svg-icon';
import dataTable from '@app/core/components/table/table.vue';
import dataTree from '@app/core/components/tree/tree.vue';
import tableYesMark from '@app/core/components/table/yes-mark.vue';
import formRow from '@app/core/components/form/form-row.vue';
import formContainer from '@app/core/components/form/form-container.vue';
import modal from '@app/core/components/modal.vue';
import options from '@app/core/components/form/options.vue';
import searchInput from '@app/core/components/form/search.vue';
import searchFilter from '@app/core/components/general/SearchFilter.vue';
import drawer from '@app/core/components/general/Drawer.vue';
import permissions from '@app/core/mixins/permissions.js';
import translator from '@app/core/mixins/translator.js';
import errors from '@app/core/mixins/errors.js';
import messages from '@app/core/mixins/messages.js';
import modalMixin from '@app/core/mixins/modal.js';
import handbook from '@app/core/mixins/handbook.js';
import events from '@app/core/mixins/events.js';
import ticker from '@app/services/ticker.js';
import claim from "@app/employee/components/claim.vue";
import sample from "@app/core/components/page-groups/sample.vue";
import '@app/observers.js';
import _ from 'lodash';
import '@app/user/boot.js';
import '@app/employee/boot.js';
import '@app/handbook/boot.js';
import '@app/institution/boot.js';
import '@app/category/boot.js';
import '@app/questionnaire/boot.js';
import '@app/questionnaire-constructor/boot.js';
import '@app/card/boot.js';
import '@app/call-center/boot.js';
import '@app/distributor/boot.js';
import '@app/file-attachment/boot.js';
import '@app/script/boot.js';
import '@app/analytics/boot.js';
import '@app/partner/boot.js';
import '@app/sample/boot.js';
import '@app/report/boot.js';

import {
    ElButton,
    ElInput,
    ElSelect,
    ElOption,
    ElSwitch,
    ElTable,
    ElTableColumn,
    ElDialog,
    ElLoadingDirective,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElAside,
    ElMenuItem,
    ElMenu,
    ElMain,
    ElContainer,
    ElCol,
    ElRow,
    ElPagination,
    ElPopover,
    ElRadioButton,
    ElRadioGroup,
    ElCheckbox,
    ElCheckboxGroup,
    ElScrollbar,
    ElTabs,
    ElTabPane,
    ElDatePicker,
    ElTimeSelect,
    ElTimePicker,
    ElDescriptions,
    ElDescriptionsItem,
    ElUpload,
    ElTree,
    ElTreeV2,
    ElOptionGroup,
    ElImage,
} from 'element-plus';

import 'dayjs/locale/uk';
import adminHP from "@app/core/components/page-groups/adminHP.vue";
import rollbackButton from "@app/category/components/import-entity/rollback-button.vue";

const app = createApp(root);
app.config.globalProperties.$_ = _;
app.config.globalProperties.$ticker = ticker;



const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            component: home,
            path: '/',
            name: 'home',
        },
        {
            component: resources,
            path: '/resources',
            name: 'resources',
        },
        {
            component: settings,
            path: '/settings',
            name: 'settings',
        },
        {
            component: infrastructures,
            path: '/infrastructures',
            name: 'infrastructures',
        },
        {
            component: categories,
            path: '/categories',
            name: 'categories',
        },
        {
            component: questionnaires,
            path: '/questionnaires',
            name: 'questionnaires',
        },
        {
            component: constructor,
            path: '/constructor',
            name: 'constructor',
        },
        {
            component: card,
            path: '/card',
            name: 'card',
        },
        {
            component: callCenter,
            path: '/call-center',
            name: 'call-center',
        },
        {
            component: distributor,
            path: '/distributor',
            name: 'distributor',
        },
        {
            component: script,
            path: '/script',
            name: 'script',
        },
        {
            component: analytics,
            path: '/analytics',
            name: 'analytics',
        },
        {
            component: partner,
            path: '/partner',
            name: 'partner'
        },
        {
            component: claim,
            path: '/claim',
            name: 'claim',
        },
        {
            component: sample,
            path: '/sample',
            name: 'sample',
        },
        {
            component: report,
            path: '/report',
            name: 'report'
        },
        {
            component: adminHP,
            path: '/load-reports',
            name: 'admin_hp'
        },
    ],
});

App.expose('router', router);
App.expose('store', store);
App.expose('app', app);

App.register(({box, config, app}) => {
    box.factory(ValidatorSymbol, () => new Validator());
    box.singleton(TransportSymbol, () => new HttpTransport());
    box.singleton(TranslatorSymbol, () => new TranslationServer('uk', 'uk'));
    app.component('page', page);
    app.component('data-table', dataTable);
    app.component('table-yes-mark', tableYesMark);
    app.component('xretail-import-rollback-button', rollbackButton);
    app.component('modal', modal);
    app.component('form-row', formRow);
    app.component('form-container', formContainer);
    app.component('options-provider', options);
    app.component('search-input', searchInput);
    app.component('svg-icon', svgIcon);
    app.component('el-container', ElContainer);
    app.component('el-main', ElMain);
    app.component('el-input', ElInput);
    app.component('el-button', ElButton);
    app.component('el-select', ElSelect);
    app.component('el-option', ElOption);
    app.component('el-switch', ElSwitch);
    app.component('el-table', ElTable);
    app.component('el-table-column', ElTableColumn);
    app.component('el-dialog', ElDialog);
    app.component('el-dropdown', ElDropdown);
    app.component('el-dropdown-item', ElDropdownItem);
    app.component('el-dropdown-menu', ElDropdownMenu);
    app.component('el-aside', ElAside);
    app.component('el-menu-item', ElMenuItem);
    app.component('el-menu', ElMenu);
    app.component('el-col', ElCol);
    app.component('el-row', ElRow);
    app.component('el-pagination', ElPagination);
    app.component('el-popover', ElPopover);
    app.component('el-radio-button', ElRadioButton);
    app.component('el-radio-group', ElRadioGroup);
    app.component('el-checkbox', ElCheckbox);
    app.component('el-checkbox-group', ElCheckboxGroup);
    app.component('el-scrollbar', ElScrollbar);
    app.component('el-tabs', ElTabs);
    app.component('el-tab-pane', ElTabPane);
    app.component('el-date-picker', ElDatePicker);
    app.component('el-time-select', ElTimeSelect);
    app.component('el-time-picker', ElTimePicker);
    app.component('el-descriptions', ElDescriptions);
    app.component('el-descriptions-item', ElDescriptionsItem);
    app.component('el-upload', ElUpload);
    app.component('el-tree', ElTree);
    app.component('el-tree-v2', ElTreeV2);
    app.component('el-option-group', ElOptionGroup);
    app.component('drawer', drawer);
    app.component('search-filter', searchFilter);
    app.component('image-cropper', ImageCropper);
    app.component('el-image', ElImage);
    app.component('form-upload', Upload);
    app.component('cropper-upload', Cropper);
    app.component('data-tree', dataTree);
    app.directive('loading', ElLoadingDirective);
    app.mixin(permissions);
    app.mixin(translator);
    app.mixin(errors);
    app.mixin(modalMixin);
    app.mixin(messages);
    app.mixin(handbook);
    app.mixin(events);
});
